<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="文件名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-input
            placeholder="请输入文件名称"
            v-decorator="['name', {rules: [{required: true, message: '请输入文件名称'}]}]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { rename } from '@/api/system/source'
import pick from 'lodash.pick'

// 表单字段
const fields = [
  'name'
]

export default {
  name: 'SourceFileRename',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      id: null,
      title: null
    }
  },
  methods: {

    init(item) {
      this.title = '文件重命名'
      this.id = item.id
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick(item, fields))
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          rename(Object.assign({ id: this.id},values )).then((res) => {
            this.confirmLoading = false
            if (res.code === 200) {
              this.$notification.success({message: '重命名成功'})
              this.handleCancel()
              this.$emit('ok', values)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.id = null
      this.title = null
    },

  }
}
</script>
