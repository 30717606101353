import { axios } from '@/utils/request'

/**
 * 获取文件夹树
 * @param parentId 父级Id
 * @return
 */
export function listDirTree(parentId) {
  return axios({
    url: '/source_manage/list_dir_tree',
    method: 'get',
    params: {parentId}
  })
}

/**
 * 获取文件夹和文件列表
 * @param params
 * @return
 */
export function list(params) {
  return axios({
    url: '/source_manage/list',
    method: 'get',
    params: params
  })
}

/**
 * 获取单个
 * @param id 自增Id
 * @return
 */
export function getOne(id) {
  return axios({
    url: '/source_manage/get_one',
    method: 'get',
    params: {id}
  })
}


/**
 * 新增文件夹
 * @param data
 * @return
 */
export function addDir(data) {
  return axios({
    url: '/source_manage/add_dir',
    method: 'post',
    data: data
  })
}

/**
 * 修改文件夹
 * @param data
 * @return
 */
export function editDir(data) {
  return axios({
    url: '/source_manage/edit_dir',
    method: 'post',
    data: data
  })
}

/**
 * 新增文件
 * @param data
 * @return
 */
export function addFile(data) {
  return axios({
    url: '/source_manage/add_file',
    method: 'post',
    data: data
  })
}

/**
 * 复制或移动
 * @param data
 * @return
 */
export function copyOrCut(data) {
  return axios({
    url: '/source_manage/copy_or_cut',
    method: 'post',
    data: data
  })
}

/**
 * 重命名
 * @param data
 * @return
 */
export function rename(data) {
  return axios({
    url: '/source_manage/rename',
    method: 'post',
    data: data
  })
}

/**
 * 删除目录或资源
 * @param id
 * @return
 */
export function delDirOrFile(id) {
  return axios({
    url: '/source_manage/del_dir_or_file',
    method: 'post',
    data: {id}
  })
}